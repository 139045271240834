import React from 'react'

export default function reviews() {
    return (
        <section className="reviews" id="testimonios">
            <div className="section__container">
                <div className="elfsight-app-e36f8fa5-d960-4585-9eb5-09f953edc930"></div>
            </div>
        </section>
    )
}
