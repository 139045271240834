import React from "react";
import "./App.scss";
import Header from "./components/header";
import Footer from "./components/footer";
import Home from "./components/pages";
import Provider from "./Provider";

function App() {

  /*const platformString = navigator.platform.toLowerCase();

  if (platformString.includes("ipad") || platformString.includes("iphone") || platformString.includes("ipod")) {

  }*/
  const appStuff = <div className="App">
                      <div className="widgets-whatsapp-facebook">
                        <div className="elfsight-app-6c734ad0-db22-4dc7-850d-4934ac5e3d16"></div>
                      </div>
                      <Header />
                      <Home />
                      <Footer />
                      <Provider />
                  </div>

  const renderApp = appStuff;

  return renderApp;
}

export default App;
