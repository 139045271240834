import React from 'react'

export default function Contacto() {
    return (
        <section className="contacto" id="contacto">
            <div className="section__container">
                <h1>Contacto</h1>
                <div className="contact-info">
                    <address>
                        <ul>
                            <li className="contacto-facebook">
                                <a href="https://www.facebook.com/amikoosvet" title="Amikoosvet Facebook">
                                    <i className="fa fa-facebook-square" aria-hidden="true"></i>
                                    <span>amikoosvet</span>
                                </a>
                            </li>
                            <li className="contacto-instagram">
                                <a href="https://www.instagram.com/amikoosvet" title="Amikoosvet Instagram">
                                    <i className="fa fa-instagram" aria-hidden="true"></i>
                                    <span>amikoosvet</span>
                                </a>
                            </li>
                            <li>
                                <a href="mailto:contacto@amikoosvet.com">
                                    contacto@amikoosvet.com
                                </a>
                            </li>
                            <li  className="contacto-whatsapp">
                                <i className="fa fa-whatsapp" aria-hidden="true"></i>
                                <a href="https://api.whatsapp.com/send?phone=527225357821&text=%C2%A1Hola%20Amikoos!%2C%20deseo%20m%C3%A1s%20informaci%C3%B3n%20sobre%20sus%20productos%20y%20servicios.">722 5357 821</a>
                            </li>
                            <li></li>
                        </ul>
                    </address>
                </div>
                <iframe id="contact-iframe" className="map-iframe" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3765.898701974065!2d-99.51627278509555!3d19.286770886967346!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x50aade504cf3332!2sCl%C3%ADnica%20Veterinaria%20AmikoosVet!5e0!3m2!1ses-419!2smx!4v1603761652150!5m2!1ses-419!2smx"  style={{border:0}}  aria-hidden="false" title="Mapa de Amikoosvet"></iframe>
            </div>
        </section>
    )
}
