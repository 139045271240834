import React from "react";
import Navigation from "./Navigation";
import Search from "./Search";
import Amikoos from "./Amikoos";

export default function Header() {
  return (
    <header className="header__container">
     {/* <div style={{width: "90%", textAlign: "center", padding: "5px", color: "blue", margin: "0 auto", fontSize: "10px"}}>
        Este sitio está bajo mantenimiento, disculpe las molestias.
  </div>*/}
      <div className="header__search--background">
        <Search />
      </div>
      <Navigation />
      <Amikoos />
    </header>
  );
}
