import React from 'react'
import { Link } from 'react-scroll'

export default function Navigation() {
    return (
        <ul className="header__navigation">
            <li><Link smooth={true} duration={800} to="acerca">Acerca</Link></li>
            <li><Link smooth={true} duration={800} to="servicios">Servicios</Link></li>
            <li><Link smooth={true} duration={800} to="testimonios">Testimonios</Link></li>
            <li><Link smooth={true} duration={800} to="contacto">Contacto</Link></li>
            <li><a href="https://amikoosvet.blogspot.com/" rel="noopener noreferrer" title="Blog AmikoosVet">Blog</a></li>
        </ul>
    )
}
