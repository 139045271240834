import React, { useState } from 'react'

export default function Search() {
    const [searchValues, setSearchValues] = useState('');

    
    const handleClick = (e) => {
         
    }

    return (
        <div className="header___search" role="searchbox">
            <div className="header__escudo">
                <img className="header__img" src="./images/Escudo-amikoosvet.svg" alt="AmikoosVet Clínica Veterinaria Escudo" />
            </div>
            <div className="header__imgAndSearch">
                <img className="header__img" src="./images/logo-amikoosvet.svg" alt="AmikoosVet Clínica Veterinaria Logotipo" />
                {/*<fieldset>
                    <input className="header__inputSearch" type="search" id="site-search" name="search" aria-label="Busca contenido en el sitio" placeholder="Buscar contenido" value={searchValues}
                    onChange={(e) => setSearchValues(e.target.value)}
                    />
                    <button type="submit" title="Buscar" onClick={handleClick}><i className="fa fa-search"></i></button>
                </fieldset>*/}
            </div>
        </div>
    )
}
