import React from 'react'
import Carousel from 'react-elastic-carousel'

const breakPoints = [
    { width: 600, itemsToShow: 1, itemsToScroll: 1 },
        { width: 900, itemsToShow: 2, itemsToScroll: 2 },
        { width: 1400, itemsToShow: 3,itemsToScroll: 3 },
];

const Items = [
    {id: 1, url:"./images/productos/uno.png", alt:"Alimeto Nupec y Royal Canin", http: "https://www.facebook.com/amikoosvet/shop/Alimento-y-Premios-695923714358803"},
    {id: 2, url:"./images/productos/dos.png", alt:"Accesorios", http: "https://www.facebook.com/107515363961527/shop/all_products"},
    {id: 3, url:"./images/productos/tres.png", alt:"Consulta Médica", http: "https://api.whatsapp.com/send?phone=527225357821&text=%C2%A1Hola%20Amikoos!%2C%20deseo%20m%C3%A1s%20informaci%C3%B3n%20sobre%20el%20servicio%20de%20consulta%20m%C3%A9dica."},
    {id: 4, url:"./images/productos/cuatro.png", alt:"Vacunas y Desparasitación", http: "https://api.whatsapp.com/send?phone=527225357821&text=%C2%A1Hola%20Amikoos!%2C%20deseo%20m%C3%A1s%20informaci%C3%B3n%20sobre%20el%20servicio%20de%20vacunaci%C3%B3n."},
];

export default function Slider() {
    return (
        <section className="slider">
            <div className="section__container">
                <Carousel breakPoints={breakPoints}>
                    {
                        Items.map( item => (
                            <div className="slider__container" key={item.id}>
                                <a href={item.http} rel="noopener noreferrer" >
                                    <img src={item.url} alt={item.alt} />
                                </a>
                            </div>
                        ))
                    }
                </Carousel>
            </div>
        </section>
    )
}
