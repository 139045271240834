import React from 'react'

export default function Modal(props) {
    let { modalBool,  closeModal, modalData} = props;

    const stylesContainer = {
        display: modalBool ? "block" : "none",
     }
    return (
        <div className="background__modal" style={stylesContainer} onClick={closeModal}>              
              <div className="modal__contents">
                  <button className="js-modal__btn--close" onClick={closeModal} title="Cerrar ventana emergente">X</button>
                  <h2>{modalData.h1}</h2>
                  <p>{modalData.description}</p>
                  <ul>
                      {
                          modalData.descrpList && modalData.descrpList.map((el,index) => <li keyprop={index}>{el}</li>)
                      }
                  </ul>
                  <p>{modalData.descrpLegend && modalData.descrpLegend}</p>
                  <a href={modalData.masInfo} title="Más información">M&aacute;s informaci&oacute;n</a>
                  {
                      modalData.agendar && <a href={modalData.agendar} title="Agendar una cita">Agendar cita</a>
                  }  

              </div>
        </div>
    )
}
