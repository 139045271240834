import React from "react";
import { Link } from "react-scroll";

export default function Footer() {
  return (
    <footer className="footer">
      <ul className="footer__navigation">
        <li>
          <Link smooth={true} duration={800} to="acerca">
            Acerca
          </Link>
        </li>
        <li>
          <Link smooth={true} duration={800} to="servicios">
            Servicios
          </Link>
        </li>
        <li>
          <Link smooth={true} duration={800} to="testimonios">
            Testimonios
          </Link>
        </li>
        <li>
          <Link smooth={true} duration={800} to="contacto">
            Contacto
          </Link>
        </li>
        <li>
          <a href="https://www.facebook.com/amikoosvet/shop/" rel="noopener noreferrer" >
            Tienda
          </a>
        </li>
        <li>
          <a href="https://amikoosvet.blogspot.com/" rel="noopener noreferrer" >
            Blog
          </a>
        </li>
      </ul>
      <p>&copy; 2020 AmikoosVet | Powered by AmikoosVet Digital</p>
    </footer>
  );
}
