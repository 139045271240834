import React from 'react'

export default function Amikoos() {
    return (
        <div className="header__amikoos">
            <div className="header__dogs">
                <img className="dogs__cachorros" src="./images/dog_puppy.png" alt="Perros Cachorros" />
                <img className="dogs__pequenas" src="./images/dog-mascara.png" alt="Perros Razas Pequeñas" />
                <img className="dogs__medianas" src="./images/dog_boxer.png" alt="Perros Razas Medianas" />
                <img className="dogs__grandes" src="./images/dog_giant.png" alt="Perros Razas Grandes" />
            </div>
            <div className="header__cats">
                <img className="cats__cachorros" src="./images/kitten.png" alt="Gatos Cachorros" />
                <img className="cats__pequenas" src="./images/persa.png" alt="Gatos Razas Pequeñas" />
                <img className="cats__comunes" src="./images/surprised_cat.png" alt="Gatos Razas comunes" />
                <img className="cats__medianas" src="./images/cat_one.png" alt="Gatos Razas Medianas" />
                <img className="cats__grandes" src="./images/ragamuffin.png" alt="Gatos Razas Grandes" />
            </div>  
        </div>
    )
}
