import React from "react";
import Slider from "../slider";
import Acerca from "./Acerca";
import Services from "./Services";
import Reviews from "./Reviews";
import Contacto from "./Contacto";

export default function Home() {
  return (
    <main className="container">
      <Slider />
      <Acerca />
      <Services />
      <Reviews />
      <Contacto />
    </main>
  );
}
