import React, {useState} from 'react'
import Modal from '../utils/Modal'
import DataServicios from '../utils/DataServicios'


export default function Services() {
    const {
        consulta,
        vacunacion,
        desparasitasion,
        esterilizacion,
        cirugia,
        hospitalizacion,
        profilaxis,
        estetica,
        pension,
        accesorios,
        alimentos
    } = DataServicios;
    const [modalBool, setModalBool] = useState(false);
    const [modalData, setModalData] = useState({});
    function openModal(data) {
        setModalData(data);
        setModalBool(true);
    }
    function closeModal(e) {
        if (e.target.className === "js-modal__btn--close" ||
            e.target.className === "background__modal") {
            setModalBool(false);
        }        
    }
    return (
        <section className="services" id="servicios">
             <div className="section__container">
                <h1>Servicios</h1>
                <Modal modalBool={modalBool} closeModal={closeModal} modalData={modalData}/>
                <div className="services__container">
                        <article className="services_service">
                            <img src="./images/svg/amikoosvet-consulta-medica.svg" alt="AmikoosVet Consulta Medica" />
                            <h2>Consulta</h2>
                            <button onClick={ () => openModal(consulta)}>+ INFORMACI&Oacute;N / AGENDAR</button>
                        </article>
                        <article className="services_service">
                            <img src="./images/svg/amikoosvet-vacunacion.svg" alt="AmikoosVet Vacunacion" />
                            <h2>Vacunaci&oacute;n</h2>
                            <button onClick={ () => openModal(vacunacion)}>+ INFORMACI&Oacute;N / AGENDAR</button>
                        </article>
                        <article className="services_service">
                            <img src="./images/svg/amikoosvet-desparasitacion.svg" alt="AmikoosVet Desparasitacion" />
                            <h2>Desparasitaci&oacute;n Interna</h2>
                            <button onClick={ () => openModal(desparasitasion)}>+ INFORMACI&Oacute;N / AGENDAR</button>
                        </article>
                        <article className="services_service">
                            <img src="./images/svg/amikoosvet-esterilizacion.svg" alt="AmikoosVet Esterilizacion" />
                            <h2>Esterilizaci&oacute;n</h2>
                            <button onClick={ () => openModal(esterilizacion)}>+ INFORMACI&Oacute;N / AGENDAR</button>
                        </article>
                        <article className="services_service">
                            <img src="./images/svg/amikoosvet-cirugia.svg" alt="AmikoosVet Cirugia" />
                            <h2>Cirug&iacute;a</h2>
                            <button onClick={ () => openModal(cirugia)}>+ INFORMACI&Oacute;N / AGENDAR</button>
                        </article>
                        <article className="services_service">
                            <img src="./images/svg/amikoosvet-hospitalizacion.svg" alt="AmikoosVet Cirugia" />
                            <h2>Hospitalizaci&oacute;n</h2>
                        </article>
                        <article className="services_service">
                            <img src="./images/svg/amikoosvet-profilaxis-dental.svg" alt="AmikoosVet Profilaxis Dental" />
                            <h2>Profilaxis Dental</h2>
                            <button onClick={ () => openModal(profilaxis)}>+ INFORMACI&Oacute;N / AGENDAR</button>
                        </article>
                        <article className="services_service">
                            <img src="./images/svg/amikoosvet-estetica.svg" alt="AmikoosVet Profilaxis Dental" />
                            <h2>Est&eacute;tica</h2>
                            <button onClick={ () => openModal(estetica)}>+ INFORMACI&Oacute;N / AGENDAR</button>
                        </article>
                        <article className="services_service accesorios">
                            <img src="./images/svg/amikoosvet-accesorios.svg" alt="AmikoosVet Profilaxis Dental" />
                            <h2>Accesorios</h2>
                            <button onClick={ () => openModal(accesorios)}>+ INFORMACI&Oacute;N</button>
                        </article>
                        <article className="services_service">
                            <img src="./images/svg/amikoos-alimentos-y-premios.svg" alt="AmikoosVet Profilaxis Dental" />
                            <h2>Alimentos y Premios</h2>
                            <button onClick={ () => openModal(alimentos)}>+ INFORMACI&Oacute;N</button>
                        </article>
                </div>        
             </div>
         </section>
    )
}
