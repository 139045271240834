import React, { useEffect } from 'react'

export default function Provider() {    

    /*async function fetchPosts() {
        const response = await fetch(`https://www.googleapis.com/blogger/v3/blogs/4570778089299124128/post?key=${process.env.REACT_APP_BLOGGER_TOKEN}`, {
            method: "GET",
            mode: "cors",
            headers: {
                "Authorization": `Bearer: AIzaSyCH20ydoz1DFKG0jHKKawBGePhFGntN3H0`,
                "Content-Type": "application/json"
            }
        });
        const data = await response.json
        console.log("tete: ", response.json())
    }

    useEffect( () => {
        fetchPosts();
    }, []);
  */

    return (
        <div>
           
        </div>
    )
}
