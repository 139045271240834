import React from "react";

export default function Acerca() {
  return (
    <section className="acerca" id="acerca">
      <div className="section__container">
        <h1>Acerca de AmikoosVet</h1>
        <div className="imgContainer">
          <img
            src="./images/fachada-amikoosvet-clinica-veterinaria.jpeg"
            alt="Fachada de la Clínica Veterinaria AmikoosVet en Lerma"
          />
        </div>
        <div className="descriptionContainer">
          <p>
            Basados en nuestra experiencia y amor por los animales, un grupo de
            profesionistas multidisciplinarios nos unimos para integrar nuestros
            conocimientos y crear una Clínica Veterinaria que satisfaga las
            necesidades de atención médica, prevención y productos necesarios
            para brindar una mejor calidad de vida y salud a los AMIKOOS (perros
            y gatos).
          </p>
          <p>
            La palabra AMIKOOS deriva de la lengua Maya que significa AMIGO, ya
            que para nosotros, un perro, gato u otro animalito es un miembro de
            la familia, no un objeto. Por lo que un AMIKOO merece una atención
            cálida y de calidad.
          </p>
          <p>
            <strong>Misión</strong>: Ser la mejor Clínica Veterinaria de la ciudad, mediante el aporte de soluciones médicas acertadas e inmediatas a nuestros pacientes; maximizando nuestro empeño por lograr constantemente un alto nivel de calidad y profesionalismo en la prestación de nuestros servicios y productos. </p>
          <p><strong>Visión</strong>: Consolidarnos como la mejor y más innovadora Clínica Veterinaria del Estado de México.</p>
          <p><strong>Valores</strong>: Confianza, Calidad, Integridad, Responsabilidad, Honestidad, Lealtad, Respeto, Pasión y Amor.
          </p>
        </div>
      </div>
    </section>
  );
}
